<template>
    <div ref="container" style="text-align: center; position: relative;">
      <div ref="spacer" id="spacer"></div>
      <Toolbar id="toolbar" ref="toolbar" class="p-p-2">
        <template #center>
          <div id="drawing_toolbar">
            <SelectButton v-model="mode" optionValue="value" :optionDisabled="el => el.value === mode" :options="[{value: 'draw', icon: 'pi pi-pencil'}, {value: 'write', icon: 'pi pi-align-justify'}]" >
              <template #option="slotProps">
                <i :class="slotProps.option.icon"></i>
              </template>
            </SelectButton> 
            <Divider layout="vertical" />
            <ColorPicker v-model="color" @change="changeColor"/>
            <Divider layout="vertical" />
            <div v-if="mode === 'draw'" style="display: flex; flex-direction: row; align-items: center;">
              <Knob v-model="epaisseur" :size="40" :min="1" :max="10" readonly valueTemplate="." @change="changeSize" style="pointer-events: none;  user-select: none;"/>
              <div style="display: flex; flex-direction: column-reverse;">
                <Button size="small" class="p-button-rounded p-button-text" icon="pi pi-minus" @click="DownSize" :disabled="value <= 0" />
                <Button size="small" class="p-button-rounded p-button-text" icon="pi pi-plus" @click="UpSize" :disabled="value >= 100" />
              </div>
            </div>
            <div v-else>
              <InputText
                class="textIntput"
                v-model="inputText"
                placeholder="Texte"
                :invalid="!inputText"
              />
            </div>
            <Divider layout="vertical" />
            <Button @click="undoLastAction" icon="pi pi-undo" class="p-button-text"/>
          </div>
          
        </template>
    </Toolbar>
      
    <!-- <button @click="saveImage">Enregistrer l'image</button> -->
      <input hidden ref="drawable_file_button" type="file" accept="image/*" @change="loadImage">
      <canvas 
        class="p-mt-4"
        ref="canvas" 
        @mousedown="startDrawing" 
        @mousemove="draw" 
        @mouseup="stopDrawing" 
        @touchstart="startDrawing" 
        @touchmove="draw" 
        @touchend="stopDrawing"
        @dblclick="addText"
        @resize="setCanvasSize"
      ></canvas>
    </div>
    <!-- Canevas pour dessiner et écrire -->
  </template>
  
  <script setup>
import { onMounted, onUnmounted, ref, defineProps, defineExpose, defineEmits, computed } from "vue";
import ColorPicker from "primevue/colorpicker";
import Slider from 'primevue/slider';
import Button from "primevue/button";
import Knob from "primevue/knob";
import Toolbar from "primevue/toolbar";
import Divider from "primevue/divider";
import SelectButton from "primevue/selectbutton";
import InputText from "primevue/inputtext";

const props = defineProps(["photo"]);
const isDrawing = ref(false);
const canvas = ref(null);
const inputText = ref(""); // Texte à insérer
const actions = ref([]); // Historique des actions
const color = ref("#000000")
const epaisseur = ref(2)
const drawable_file_button = ref()
const mode = ref('draw')
const toolbar = ref(null);
const container = ref(null)
const spacer = ref(null)
const emit = defineEmits()


onMounted(() => {
  changeStroke()
  loadImage(props.photo)
})

const context = computed(() => {
  return canvas.value.getContext("2d")
})

const setCanvasSize = () => {
  console.log("je resize")
  const rect = canvas.value.parentElement.getBoundingClientRect();
  // Ajustez la taille interne du canvas
  canvas.value.width = rect.width;
  canvas.value.height = rect.height;
};


const loadImage = (imgURL) => {
  const img = new Image();
  img.onload = () => {
    // Dimensions maximales du canvas
    const maxCanvasWidth = window.innerWidth * 0.9; // 90vw
    const maxCanvasHeight = window.innerHeight * 0.6; // 60vh

    // Calculer les dimensions en respectant les proportions
    const scale = Math.min(maxCanvasWidth / img.width, maxCanvasHeight / img.height);

    const newWidth = img.width * scale;
    const newHeight = img.height * scale;

    // Mettre à jour les dimensions du canvas
    canvas.value.width = newWidth;
    canvas.value.height = newHeight;

    // Dessiner l'image redimensionnée sur le canvas
    context.value.drawImage(img, 0, 0, newWidth, newHeight);

    // Sauvegarder l'action
    saveAction();
  };
  img.src = imgURL;
};


const saveAction = () => {
  if (context.value) {
    // Sauvegarder l'état actuel du canevas
    actions.value.push(context.value.getImageData(0, 0, canvas.value.width, canvas.value.height));
  }
};

const startDrawing = (event) => {
  // Si le geste implique plusieurs doigts, désactiver le dessin et laisser le zoom par défaut
  if (event.touches && event.touches.length > 1) {
    isDrawing.value = false; // Désactiver le dessin
    return; // Laisser le navigateur gérer le zoom par défaut
  }

  if (mode.value === "draw") {
    isDrawing.value = true;
    const rect = canvas.value.getBoundingClientRect();
    const scaleX = canvas.value.width / rect.width;
    const scaleY = canvas.value.height / rect.height;

    const x = ((event.touches ? event.touches[0].clientX : event.clientX) - rect.left) * scaleX;
    const y = ((event.touches ? event.touches[0].clientY : event.clientY) - rect.top) * scaleY;

    context.value.beginPath();
    context.value.moveTo(x, y);
  } else {
    addText(event);
  }
};

const draw = (event) => {
  // Si le geste implique plusieurs doigts, ne pas dessiner
  if (event.touches && event.touches.length > 1) {
    isDrawing.value = false; // Désactiver le dessin
    return; // Laisser le navigateur gérer le zoom par défaut
  }

  if (!isDrawing.value) return;
  event.preventDefault(); // Empêche le scrolling uniquement pour le dessin

  const rect = canvas.value.getBoundingClientRect();
  const scaleX = canvas.value.width / rect.width;
  const scaleY = canvas.value.height / rect.height;

  const x = ((event.touches ? event.touches[0].clientX : event.clientX) - rect.left) * scaleX;
  const y = ((event.touches ? event.touches[0].clientY : event.clientY) - rect.top) * scaleY;

  context.value.lineTo(x, y);
  context.value.strokeStyle = color.value; // Couleur du dessin
  context.value.lineWidth = epaisseur.value; // Épaisseur du dessin
  context.value.stroke();
};

const stopDrawing = () => {
  if (isDrawing.value) {
    isDrawing.value = false;
    context.value.closePath();
    saveAction(); // Sauvegarder l'état après avoir terminé un trait
  }
};

const addText = (event) => {
  if (!inputText.value){ // Ne rien faire si aucun texte n'est saisi

    return;
  }  
  const rect = canvas.value.getBoundingClientRect();
    const scaleX = canvas.value.width / rect.width;
    const scaleY = canvas.value.height / rect.height;

    const x = ((event.touches ? event.touches[0].clientX : event.clientX) - rect.left) * scaleX;
    const y = ((event.touches ? event.touches[0].clientY : event.clientY) - rect.top) * scaleY;

  context.value.font = "20px Arial";
  context.value.fillStyle = color.value; // Couleur du texte
  context.value.fillText(inputText.value, x, y);

  saveAction(); // Sauvegarder l'état après ajout du texte
  inputText.value = ""; // Réinitialise le champ de texte
};

const undoLastAction = () => {
  if (actions.value.length > 1) {
    actions.value.pop(); // Supprime la dernière action
    context.value.clearRect(0, 0, canvas.value.width, canvas.value.height); // Efface tout le canevas

    // Redessine tout depuis les actions restantes
    if (actions.value.length > 0) {
      context.value.putImageData(actions.value[actions.value.length - 1], 0, 0);
    }
  }
};

const changeColor = (el) => {
  color.value = "#" + el.value
  changeStroke()
}

const UpSize = () => {
  if(epaisseur.value < 10){
    epaisseur.value++
    changeStroke()
  }
}
const DownSize = () => {
  if(epaisseur.value > 1){
    epaisseur.value--
    changeStroke()
  }
}

const changeStroke = () => {
  const text = document.querySelector('text.p-knob-text')
  text.style.fontSize = (2 + epaisseur.value) + 'em'
  text.style.fill = color.value

}

const updateToolbarScale = (scale = null) => {
  const zoomLevel = window.devicePixelRatio || 1; // Niveau de zoom
  const toolbarEl = toolbar.value
  const spacer = document.getElementById("spacer")
  if(toolbarEl){
    
    if(!scale){
      scale = 1 / zoomLevel;
    }else{
      scale = 1 / scale;
      const offsetX = window.visualViewport.offsetLeft || 0;
      let offsetY = window.visualViewport.offsetTop || 0;

      if(offsetY< 95){
        offsetY = 90
      }
      if(scale === 1){
        toolbarEl.$el.style.position = "sticky"
        spacer.style.height = "0px"
        toolbarEl.$el.style.width = "100%"
        toolbarEl.$el.style.top = "10px"
      }else{
        toolbarEl.$el.style.left = `calc(${offsetX}px + ${2.5*scale}%)`;
        toolbarEl.$el.style.top = `calc(${offsetY}px + ${10*scale}px)`;
        toolbarEl.$el.style.position = "fixed"
        spacer.height = "90px"
        toolbarEl.$el.style.width = "95%"
      }
    }
    // Appliquer la mise à l'échelle à la toolbar
    toolbarEl.$el.style.transform = ` scale(${scale})`;
  }
    
};

onMounted(() => {
  const canvasEl = canvas.value;

  canvasEl.addEventListener("mouseleave", stopDrawing);

  onUnmounted(() => {
    canvasEl.removeEventListener("mouseleave", stopDrawing);
  });
});

window.addEventListener("resize", updateToolbarScale);


window.visualViewport.addEventListener('scroll', () => {
  const zoomLevel = window.visualViewport.scale;
  updateToolbarScale(zoomLevel);
});

window.visualViewport.addEventListener('resize', () => {
  const zoomLevel = window.visualViewport.scale;
  updateToolbarScale(zoomLevel);
});


const saveCustomPhoto = () => {
  const image = canvas.value.toDataURL("image/png");
  emit("sendPhoto", image);
}

defineExpose({saveCustomPhoto})



</script>
<style>
  canvas {
    border: 1px solid #000;
    touch-action: pinch-zoom; /* Empêche le défilement sur les appareils tactiles */
    max-width: 800px;
    max-height: 60vh;
    /* width: 90vw; */
  }

  #drawing_toolbar{
    display: flex;
    gap: 30px;
    align-items: center;
  }

  #toolbar{
    padding: 15px;
    border-radius: 30px;
    position: sticky;
    top: 10px;
    z-index: 100;
    transform-origin: top left;
  } 

  .textIntput{
    max-width: 80px;
  }

  @media screen and (max-width: 1024px) {
    #drawing_toolbar {
      gap: 0px; /* Réduit l'espacement entre les éléments */
    }

    #toolbar{
      top: 10px;
      position: sticky;
    }

  }

  </style>
  