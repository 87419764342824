export default class VisiteSousPlancher {
  id: string;
  nom?: string;
  prenom?: string;
  telephone?: string;
  email?: string; // Renommé depuis mail
  adresse?: string;
  code_postal?: string;
  ville?: string;
  statut_occupant?: string;
  age_habitation?: string;
  type_maison?: string;
  surface_a_isoler?: number; // en m²
  zone_isolation?: string;
  hauteur_disponible_isolation?: number; // en mm
  acces_vehicule: boolean;
  distance_parking?: number; // en mètres
  plancher_bois: boolean;
  etat_plancher?: string;
  type_sous_plancher?: string;
  accessibilite: boolean;
  espace_ventile?: string;
  modifications_ouvertures: boolean;
  type_modifications?: string;
  problemes_humidite: boolean;
  pare_vapeur_prevoir: boolean;
  type_reseaux?: string;
  gaines_conduites: boolean;
  nombre_gaines?: number;
  type_gaines?: string;
  presence_vannes: boolean;
  type_isolation?: string;
  epaisseur_isolant?: number; // en mm
  observations?: string;
  conducteur_travaux?: string;
  realise_par?: any; 
  vpo?: any; 

  constructor() {
      this.id = 'notSet';
      this.nom = undefined;
      this.prenom = undefined;
      this.telephone = undefined;
      this.email = undefined;
      this.adresse = undefined;
      this.code_postal = undefined;
      this.ville = undefined;
      this.statut_occupant = undefined;
      this.age_habitation = undefined;
      this.type_maison = undefined;
      this.surface_a_isoler = undefined;
      this.zone_isolation = undefined;
      this.hauteur_disponible_isolation = undefined;
      this.acces_vehicule = false;
      this.distance_parking = undefined;
      this.plancher_bois = false;
      this.etat_plancher = undefined;
      this.type_sous_plancher = undefined;
      this.accessibilite = false;
      this.espace_ventile = undefined;
      this.modifications_ouvertures = false;
      this.type_modifications = undefined;
      this.problemes_humidite = false;
      this.pare_vapeur_prevoir = false;
      this.type_reseaux = undefined;
      this.gaines_conduites = false;
      this.nombre_gaines = undefined;
      this.type_gaines = undefined;
      this.presence_vannes = false;
      this.type_isolation = undefined;
      this.epaisseur_isolant = undefined;
      this.observations = undefined;
      this.conducteur_travaux = undefined;
      this.realise_par = undefined;
      this.vpo = undefined;
  }

  get fullAdresse(): string {
      return `${this.adresse} ${this.code_postal} ${this.ville}`;
  }
}
