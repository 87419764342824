import VisiteSousPlancher from '@/models/visites/VisisteSousPlancher'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import Visite from '@/models/visites/Visite'

/**
* @Name VisiteSousPlanchersApiClientUrlsInterface
* @description
* Interface for the VisiteSousPlanchers urls used to avoid hard-coded strings
*/
export interface VisiteSousPlanchersApiClientUrlsInterface {
    getVisiteSousPlanchers: string
    getVisiteSousPlancher: string
    postVisiteSousPlancher: string
    putVisiteSousPlancher: string
    deleteVisiteSousPlancher: string

    copyFromVisite: string
}

/**
* @Name VisiteSousPlanchersApiClientInterface
* @description
* Interface for the VisiteSousPlanchers api client module
*/
export interface VisiteSousPlanchersApiClientInterface {
    getVisiteSousPlanchers: (apiFilters?: any) => Promise<VisiteSousPlancher[]>
    getVisiteSousPlancher: (visiteSousPlancher: VisiteSousPlancher) => Promise<VisiteSousPlancher>
    sendVisiteSousPlancher: (visiteSousPlancher: VisiteSousPlancher) => Promise<VisiteSousPlancher>
    deleteVisiteSousPlancher: (visiteSousPlancher: VisiteSousPlancher) => Promise<VisiteSousPlancher>

    copyFromVisite: (visite: Visite) => Promise<VisiteSousPlancher>
}

/**
* @Name VisiteSousPlanchersApiClientModel
* @description
* Implements the VisiteSousPlanchersApiClientInterface interface
*/
export class VisiteSousPlanchersApiClientModel implements VisiteSousPlanchersApiClientInterface {
    private readonly urls!: VisiteSousPlanchersApiClientUrlsInterface
    constructor(urls: VisiteSousPlanchersApiClientUrlsInterface) {
        this.urls = urls
    }

    getVisiteSousPlanchers(apiFilters?: any): Promise<VisiteSousPlancher[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getVisiteSousPlanchers,
            requiresToken: false,
            filters: apiFilters
        }
        return HttpClient.get<VisiteSousPlancher[]>(getParameters)
    }

    getVisiteSousPlancher(visiteSousPlancher: VisiteSousPlancher): Promise<VisiteSousPlancher> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getVisiteSousPlancher.replace('$(visiteSousPlancherId)', visiteSousPlancher.id),
            requiresToken: false,
        }
        return HttpClient.get<VisiteSousPlancher>(getParameters)
    }

    sendVisiteSousPlancher(visiteSousPlancher: VisiteSousPlancher): Promise<VisiteSousPlancher> {
        if (visiteSousPlancher.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putVisiteSousPlancher.replace('$(visiteSousPlancherId)', visiteSousPlancher.id),
                requiresToken: false,
                payload: visiteSousPlancher,
            }
            return HttpClient.put<VisiteSousPlancher>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postVisiteSousPlancher.replace('$(visiteSousPlancherId)', visiteSousPlancher.id),
                requiresToken: false,
                payload: visiteSousPlancher,
            }
            return HttpClient.post<VisiteSousPlancher>(getParameters)
        }
    }

    deleteVisiteSousPlancher(visiteSousPlancher: VisiteSousPlancher): Promise<VisiteSousPlancher> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteVisiteSousPlancher.replace('$(visiteSousPlancherId)', visiteSousPlancher.id),
            requiresToken: false,
        }
        return HttpClient.delete<VisiteSousPlancher>(getParameters)
    }

    copyFromVisite(visite: Visite): Promise<VisiteSousPlancher> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.copyFromVisite.replace('$(visiteId)', visite.id),
            requiresToken: false,
        }
        return HttpClient.post<VisiteSousPlancher>(getParameters)
    }
}
