import {
    VisiteSousPlanchersApiClientUrlsInterface,
    VisiteSousPlanchersApiClientInterface,
    VisiteSousPlanchersApiClientModel
} from '@/models/api-client/visites_sous_plancher/VisiteSousPlanchersApiClient'

const urls: VisiteSousPlanchersApiClientUrlsInterface = {
    getVisiteSousPlanchers: process.env.VUE_APP_API_URL + '/api/visites_sp',
    getVisiteSousPlancher: process.env.VUE_APP_API_URL + '/api/visites_sp/$(visiteSousPlancherId)',
    postVisiteSousPlancher: process.env.VUE_APP_API_URL + '/api/visites_sp',
    putVisiteSousPlancher: process.env.VUE_APP_API_URL + '/api/visites_sp/$(visiteSousPlancherId)',
    deleteVisiteSousPlancher: process.env.VUE_APP_API_URL + '/api/visites_sp/$(visiteSousPlancherId)',

    copyFromVisite: process.env.VUE_APP_API_URL + '/api/visites_sp/copy/$(visiteId)'
}

// instantiate the VisiteSousPlanchersApiClient pointing at the url that returns static json mock \data
const visiteSousPlanchersApiClient: VisiteSousPlanchersApiClientInterface = new VisiteSousPlanchersApiClientModel(urls)

// export our instance
export default visiteSousPlanchersApiClient
